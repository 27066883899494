import { navigationModules as nav } from '@myci/module-index';
import { DownloadReportButton } from '@myci/domain-credit-report';

export const appNavigation = [
	{
		...nav.myReport,
	},
	{
		...nav.productsFO,
		sublinks: [nav.overview, nav.creditReport, nav.thirdPartySharing],
	},
	{
		...nav.alerts,
	},
	{
		...nav.foHelp,
	},
];

export const customNavigation = [
	{
		name: 'downloadReport',
		component: DownloadReportButton,
	},
];
